/* Existing CSS */
.form-container {
    position: relative;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@keyframes slideInFromBottom {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  	

.animated-container {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.form-container input,
.form-container textarea,
.ant-input-affix-wrapper,
.custom-number-input {
    border: 1px solid #ccc;
    background-color: transparent !important;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    color: black;
}

.form-container input:hover,
.ant-input-affix-wrapper,
.form-container textarea:hover,
.custom-number-input:hover {
    border-color: #007BFF; 
    background-color: rgba(0, 123, 255, 0.1); 
}
.form-container input::placeholder,
.form-container textarea::placeholder,
.custom-number-input::placeholder {
    color: black !important;
    opacity: 1 !important; 
}

@media (max-width: 768px) {
    .form-container {
        padding: 10px;
    }
    .form-container h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .form-container {
        padding: 5px;
    }
    .form-container h2 {
        font-size: 1.2em;
    }
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../img/FormBackground.png');
    background-size: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.15; /* Adjust this value between 0 and 1 for desired lightness */

  }

  .upload-section {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
}

.upload-section h3 {
    margin-bottom: 16px;
    color: #333;
}

.qr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.qr-image {
    max-width: 300px;
    height: auto;
}

.ant-upload-list {
    margin-top: 10px;
}

.ant-upload-list-item {
    margin-top: 8px;
}

.custom-number-input {
    width: 100%;
}

.form-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.warning-message {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin: 8px 0;
    background-color: #FFF7F2;
    border: 1px solid #FFE4D4;
    border-radius: 4px;
    color: #D46B08;
  }
  
  .warning-message .anticon-exclamation-circle {
    font-size: 16px;
    margin-right: 8px;
  }
  .form-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.upload-section {
    margin: 24px 0;
}

.warning-message {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin: 8px 0;
    background-color: #FFF7F2;
    border: 1px solid #FFE4D4;
    border-radius: 4px;
    color: #D46B08;
}

.warning-message .anticon {
    margin-right: 8px;
    font-size: 16px;
}

.animated-container {
    animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.custom-number-input {
    width: 100%;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    z-index: 0;
    pointer-events: none;
}
  
