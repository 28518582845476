/* AdminPaymentAprove.css */
/* .container {
    font-family: "Arial", sans-serif;
    background-color: #f7f9fc;
    padding: 20px;
    min-height: 100vh;
  } */
  
  .header {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .tilesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .tile {
    width: 300px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .tileHover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .userDetails {
    margin-bottom: 15px;
  }
  
  .name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .email {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
  }
  
  .phone {
    font-size: 1rem;
    color: #777;
  }
  
  .amount {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  .date{
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .comment {
    font-size: 0.9rem;
    color: #555;
  }
  
  .actions {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  .approveButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .rejectButton {
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
  }
  