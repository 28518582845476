/* Main Title for the entire body of the page */
.admin-dashboard-title {
    width: 100%;
    text-align: center; /* Center-align title */
    padding: 20px 0;
    background-color: #f5f5f5; /* Light background color for the title area */
    margin-bottom: 20px; /* Space below the title */
}

.admin-dashboard-title h2 {
    font-size: 2rem; /* Large font size for the title */
    font-weight: bold; /* Make it bold */
    color: #333; /* Default color */
}

.admin-dashboard-title h2 span {
    color: red; /* Highlight admin name in red */
}

/* Admin home content layout */
.admin-home-container {
    display: flex;
    flex-direction: column; /* Stack content vertically by default */
    padding: 20px;
    width: 100%;
}

/* Main content layout: Sidebar (30%) and Dashboard (70%) */
.admin-home-content {
    display: flex;
    flex-direction: column; /* Stack sections vertically by default */
    width: 100%;
}



/* Dashboard cards container (70% width) */
.admin-home-dashboard {
    width: 100%; /* Full width on mobile */
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 15px; /* Rounded corners */
}

/* Responsive grid for the dashboard cards */
.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

/* Style for each individual card */
.dashboard-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 20px;
    background-color: #fff;
    transition: all 0.3s ease;
}
.dashboard-card:hover {
    transform: translateY(-5px); /* Slight lift effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
    cursor: pointer; /* Changes cursor to pointer on hover */
}

/* Card header style */
.dashboard-card__header {
    padding: 16px;
    border-bottom: 1px solid #eee;
}

/* Card title */
.dashboard-card__title {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
}

/* Card body */
.dashboard-card__body {
    padding: 16px;
    flex-grow: 1;
}

/* Description in the body of the card */
.dashboard-card__description {
    margin: 0;
    color: #000000;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: large;
}

/* Card footer style */
.dashboard-card__footer {
    padding: 16px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
}

/* Button inside card footer */
.dashboard-card__button {
    padding: 8px 16px;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

/* Optional hover effect for the button */
.dashboard-card__button:hover {
    background-color: #0056b3;
    color: white;
}

/* Media query for larger screens (min-width: 768px) */
@media (min-width: 768px) {
    .admin-home-content {
        flex-direction: row; /* Arrange content side by side */
        justify-content: space-between;
    }

    .admin-home-sidebar {
        width: 30%; /* Sidebar takes 30% of the width on larger screens */
        margin-bottom: 0; /* Remove margin when sidebar is side by side */
    }

    .admin-home-dashboard {
        width: 70%; /* Dashboard takes 70% of the width on larger screens */
    }
}

