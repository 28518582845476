/* Admin Sidebar Container */
.admin-home-sidebar {
    width: 100%;
    max-width: 350px; /* Max width for larger screens */
    padding: 20px;
    background-color: #f0f0f0; /* Light background for the sidebar */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Card Component for Admin Details */
.admin-card {
    border-radius: 15px;
    background: #ffffff; /* White background inside the card */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1); /* Light shadow for card */
    padding: 20px;
    display: flex; /* Flexbox to align items horizontally */
    align-items: center; /* Align avatar and text vertically */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Admin Card */
.admin-card:hover {
    transform: translateY(-8px); /* Card lift effect on hover */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Row and Col Layout for Admin Details */
.admin-card .label {
    font-weight: 600;
    color: #00aaff; /* Blue color for labels */
    display: block; /* Ensure label takes up full width */
    text-align: left; /* Left align the label */
    margin-bottom: 5px; /* Space between label and value */
}

.admin-card .value {
    color: #555; /* Slightly muted color for values */
    font-size: 1rem;
    line-height: 1.5; /* Increased line height for readability */
    display: block; /* Ensure value takes up full width */
    margin-bottom: 10px; /* Space below the value */
}

/* Divider Styling */
.ant-divider {
    margin: 16px 0;
    border-color: #e0e0e0;
}


/* Ensure rows align left */
.admin-card .ant-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align children to the left */
    margin-bottom: 15px;
}

.admin-card .ant-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items inside columns to the left */
}
/* Icon Row Styling (Footer) - Targeting the footer row specifically */
.admin-card .footer-icons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between; /* Distribute icons evenly */
    align-items: center;
    flex-direction: row; /* Ensure the icons are on the same line */
}

/* Icon Styling */
.admin-card .footer-icons .ant-col {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #00aaff;
}

/* Styling for Avatar Image (in case an image is used) */
.admin-card .admin-avatar-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%; /* Circular avatar */
    object-fit: cover;
    border: 2px solid #ffffff; /* Optional border around the avatar */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Soft shadow for the avatar */
}

/* Admin Info Container */
.admin-info-container {
    display: flex; /* Flexbox for horizontal alignment */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space between heading and avatar */
    width: 100%; /* Make it take the full width */
    border-bottom: 3px solid #00aaff; /* Underline with blue */
}

/* Admin Section Heading */
.admin-info-container span {
    margin: 0; /* Remove margin to avoid extra space */
    font-size: 1.2rem;
    font-weight: 700;
    color: #333; /* Darker text for the heading */
    text-transform: uppercase; /* Uppercase for emphasis */
    letter-spacing: 1px;
    padding-bottom: 10px;
    flex-grow: 1; /* Let the heading take available space */
}

/* AdminSidebar.css */
.admin-home-sidebar .footer-icons {
    text-align: center; /* Align the button in the center */
    margin-top: 20px;
  }
  
  .admin-home-sidebar .custom-button {
    background-color: #1890ff; /* Blue background color */
    color: white; /* White text color */
    border: none; /* Remove border */
    font-size: 16px; /* Set font size */
    padding: 10px 20px; /* Add padding */
    border-radius: 5px; /* Round corners */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  .admin-home-sidebar .custom-button:hover {
    background-color: #40a9ff; /* Lighter blue on hover */
  }
  
  .admin-home-sidebar .custom-button:focus {
    outline: none; /* Remove the outline when the button is focused */
  }
  